@import "../../../style/index.scss";

.navAuth {
    background: transparent !important;
    position: absolute;
    z-index: 999;
    width: 50%;

    @media (max-width: 992px) {
        width: 100%;
    }

    .nav-link {
        position: relative;
        @include text($color: $color-grey, $size: 14px);
        padding: 10px !important;

        &:hover {
            color: $color-black !important;
        }
    }

    // style logo
    .navbar-brand {
        position: relative;

        @media (max-width: 992px) {
            transform: scale(.75);
        }

        &::after {
            content: "";
            position: absolute;
            height: 40px;
            width: 1px;
            background: $color-black;
            top: 50%;
            right: -7.5px;
            transform: translateY(-50%);
        }
    }

}