@import "../../style/index.scss";

.hero {
    position: relative;
}

.dropdown-search {
    position: absolute;
    height: 120px;
    width: 35%;
    overflow-y: scroll;
    right: 0;
    transform: translateX(-15%);
    padding-right: 5%;
    background-color: white;
    padding-left: 10px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 10Px;
}

.dropdown-flea {
    position: absolute;
    height: 120px;
    width: 70%;
    overflow-y: scroll;
    right: 18px;
    transform: translateX(-10%);
    padding-right: 5%;
    background-color: white;
    padding-left: 10px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 10Px;
}

.dropdown-row {
    cursor: pointer;
}

.dropdown-row:hover {
    background-color: rgba(40, 93, 162, 0.062745098);
}

.banner-hero {
    height: 500px;
    width: 100%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
    padding: 50px;
    text-align: center;

    h1 {
        @include title($color: $color-white, $size: 60px);
        z-index: 1;
    }

    p {
        @include text($color: $color-white, $size: 18px);
        z-index: 1;
    }
}

.banner-hero::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #11111150;
    border-radius: 15px;
}

.search-section {
    position: absolute;
    width: 1000px;
    top: 57.5%;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 1024px) {
        position: unset;
        top: 0;
        left: 0;
        width: 100%;
        transform: translateX(0);
    }

    li.nav-item {
        width: 275px;
        margin-right: 5px;

        @media (max-width: 1024px) {
            margin: auto;
            width: 49%;
        }
    }

    li.nav-item button {
        width: 275px;
        background: $color-yellow-light;
        border-radius: 5px 5px 0 0;
        padding: 7.5px 30px;
        margin-right: 7.5px;
        @include text($color: $color-black, $weight: 400);

        @media (max-width: 1024px) {
            width: 100% !important;
            min-height: 60px;
        }
    }

    li.nav-item button:hover {
        background: $color-yellow;
    }

    li.nav-item button.active {
        background: $color-yellow !important;
        font-weight: 700 !important;
    }
}

.tab-content {
    background: $color-white;
    border-radius: 0 15px 15px 15px !important;
}

.tab-pane {
    background: $color-white;
    border-radius: 0 15px 15px 15px !important;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, .1);

    h3 {
        @include title;
        padding-bottom: 25px;
    }

    .search {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .btn-filter-category,
        .btn-filter-brocante {
            @include text-input($color: $color-black);
            background-color: $color-grey-light;

            img {
                margin-left: 12.5px;
            }

            @media (max-width: 1024px) {
                width: 100% !important;
            }
        }

        .search-bar {
            ::placeholder {
                @include text-input;
            }

            .btn-filter-category,
            .btn-filter-brocante {
                @include icon-style;
                border-radius: 8px 0 0 8px !important;
            }

            .input-search-article,
            .input-search-city {
                background: $color-grey-light;
                border: none;
                @include text-input($color: $color-black);
            }

            .input-search-city.city {
                border-radius: 8px !important;
            }

            .input-search-article:focus,
            .input-search-city:focus {
                box-shadow: none;
            }

            .btn-search {
                @include icon-style($fill: $color-white, $background: $color-yellow);
                border-radius: 0 8px 8px 0 !important;
            }

            .btn-location {
                @include icon-style($background: $color-grey-light);
                border-radius: 8px 8px 0 !important;
            }
        }
    }
}

.btn-add {
    font-size: 16px !important;
    padding: 10px 30px !important;
}

@media (max-width: 1024px) {
    .nav-pills {
        margin-top: 25px;
    }
}