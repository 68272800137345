@import "../../style/index.scss";

.info-card {
    position: relative;
    min-height: 350px;
    border-radius: 20px;
    margin: 15px;

    &::after {
        content: "";
        position: absolute;
        background-image: url("../../assets/texture.png");
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: start;
        padding: 45px;

        h3 {
            @include title($size: 30px);
        }

        p {
            @include text($size: 18px);
        }

        @media (max-width: 1024px) {
            padding: 50px 25px;
        }
    }

    .col-img {
        display: flex;
        justify-content: center;
        position: relative;

        img {
            position: absolute;
            bottom: 0;
            height: 110%;
        }
    }

    &.card-left {
        background: $background-blue-light;
    }

    &.card-right {
        background: $color-yellow-light;
    }


}