@import "../../style/index.scss";

.about {
    background-color: #FFF7DA;
    margin: 0;
}

.about1 {
    background-color: #FFF7DA;
    position: -webkit-sticky;
    /* Safari & IE */
    position: sticky;
    top: 0;
    background-image: url("../../assets/about1.png");
    background-repeat: no-repeat;
    background-position: 50% 100%;
    box-shadow: 230px 149px 110px rgba(221, 187, 64, 0.01), 129px 84px 92px rgba(221, 187, 64, 0.02), 57px 37px 68px rgba(221, 187, 64, 0.03), 14px 9px 38px rgba(221, 187, 64, 0.03), 0px 0px 0px rgba(221, 187, 64, 0.03);
    min-height: 100vh;
    padding-top: 200px;
    padding-right: 110px;
    justify-content: right;
    display: flex;
}

.about2 {
    background-color: #FFF7DA;
    position: -webkit-sticky;
    /* Safari & IE */
    position: sticky;
    top: 0;
    background-image: url("../../assets/about2.png");
    background-repeat: no-repeat;
    background-position: 65% 100%;
    box-shadow: 230px 149px 110px rgba(221, 187, 64, 0.01), 129px 84px 92px rgba(221, 187, 64, 0.02), 57px 37px 68px rgba(221, 187, 64, 0.03), 14px 9px 38px rgba(221, 187, 64, 0.03), 0px 0px 0px rgba(221, 187, 64, 0.03);
    justify-content: left;
    display: flex;
    padding-top: 150px;
    padding-left: 110px;
    min-height: 100vh;
}

.about3 {
    background-color: #FFF7DA;
    position: -webkit-sticky;
    /* Safari & IE */
    position: sticky;
    top: 0;
    background-image: url("../../assets/about3.png");
    background-repeat: no-repeat;
    background-position: 35% 100%;
    box-shadow: 230px 149px 110px rgba(221, 187, 64, 0.01), 129px 84px 92px rgba(221, 187, 64, 0.02), 57px 37px 68px rgba(221, 187, 64, 0.03), 14px 9px 38px rgba(221, 187, 64, 0.03), 0px 0px 0px rgba(221, 187, 64, 0.03);
    justify-content: left;
    display: flex;
    padding-top: 150px;
    padding-left: 300px;
    min-height: 100vh;
}

.about-card {
    @include text($color: black, $size: 16px);
    padding: 50px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 230px 149px 110px rgba(221, 187, 64, 0.01), 129px 84px 92px rgba(221, 187, 64, 0.02), 57px 37px 68px rgba(221, 187, 64, 0.03), 14px 9px 38px rgba(221, 187, 64, 0.03), 0px 0px 0px rgba(221, 187, 64, 0.03);
    border-radius: 20px;
    line-height: 165%;
    flex-direction: column;
}

.about-card h2 {
    font-weight: 700 !important;
    margin-bottom: 25px;
}

.about-card p {
    line-height: 165% !important;
}

.card1 {
    width: 35%;
    max-width: 540px;
    height: 50%;
}

.card2 {
    width: 31%;
    height: 50%;

}

.card3 {
    width: 50%;
    max-width: 722px;
    height: 50%;
}

@media (max-width: 768px) {

    .about1,
    .about2,
    .about3 {
        justify-content: center;
        padding-right: 0;
        padding-left: 0;
    }

    .card1 {
        width: 100%;
        max-width: 540px;
        height: 50%;
    }

    .card2 {
        width: 80%;
        height: 50%;

    }

    .card3 {
        width: 80%;
        max-width: 722px;
        height: 50%;
    }
}