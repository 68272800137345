@import "../../../style/index.scss";

.forgot-password {
    h1 {
        @include title($size: 28px);
    }

    p {
        @include text;
    }

    form {
        width: 50%;

        @media (max-width: 1024px) {
            width: 100%;
        }


        .btn-submit {
            background: $color-blue;
            @include text($color: $color-white, $size: 20px, $weight: 700);
            display: flex !important;
            justify-content: center !important;
            width: 100%;

            &:hover {
                background: $color-blue-light;
            }
        }



        label {
            @include text($color: #5C5C5C, $size: 14px, $weight: 600);

            &.form-check-label {
                @include text($color: $color-grey, $size: 14px, $weight: 400);
            }
        }

        .form-control,
        .form-select {
            height: 50px;
            border-radius: 5px;
            border-color: $color-yellow;

            &::placeholder {
                @include text($color: $color-grey, $size: 14px, $weight: 400);
            }

            &:focus {
                box-shadow: none;
            }
        }

        .input-group {
            .form-control {
                border-top: 1px solid $color-yellow;
                border-bottom: 1px solid $color-yellow;
                border-left: 1px solid $color-yellow;
                border-right: 0px solid;

                &::after {
                    display: none;
                }
            }

            .input-group-text {
                background: $color-white;
                border-top: 1px solid $color-yellow;
                border-bottom: 1px solid $color-yellow;
                border-right: 1px solid $color-yellow;
            }
        }


        .form-select {
            @include text($color: $color-grey, $size: 14px, $weight: 400);
        }
    }

}