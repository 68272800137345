@import "../../../style/index.scss";


.login {
  background-color: #FFF7DA;
  margin: 0;
  background-image: url("../../../assets/bg-login.png");
  background-repeat: no-repeat;
  background-size: 55%;
  background-position: 100% 100%;

  .link-mdp-forgot {
    @include text;
  }

  .link-register {
    @include text;
    text-decoration: none;

    span {
      text-decoration: underline;
    }
  }
}