@import "../../../style/index.scss";

.UpdateArticle {
  background-color: #FFF7DA;
  margin: 0;
  background-image: url("../../../assets/bg_article.png");
  background-repeat: no-repeat;
  background-size: 40%;
  background-position: 10% 25%;
}

//form
.form-update-article {
  padding: 50px 100px;

  @media (max-width: 1350px) {
    padding: 50px 50px !important;
  }

  h1 {
    @include title($size: 38px);
  }

  label {
    @include text($color: #5C5C5C, $size: 14px, $weight: 600);

    &.form-check-label {
      @include text($color: $color-grey, $size: 14px, $weight: 400);
    }
  }

  .form-control,
  .form-select {
    height: 50px;
    border-radius: 5px;
    border-color: $color-yellow;

    &::placeholder {
      @include text($color: $color-grey, $size: 14px, $weight: 400);
    }

    &:focus {
      box-shadow: none;
    }
  }

  .input-group {
    .form-control {
      border-top: 1px solid $color-yellow;
      border-bottom: 1px solid $color-yellow;
      border-left: 1px solid $color-yellow;
      border-right: 0px solid;

      &::after {
        display: none;
      }
    }

    .input-group-text {
      background: $color-white;
      border-top: 1px solid $color-yellow;
      border-bottom: 1px solid $color-yellow;
      border-right: 1px solid $color-yellow;
    }
  }

  .form-check-input {
    border-radius: 5px;
    border-color: $color-yellow;

    &:focus,
    &:checked {
      background: $color-yellow;
    }
  }

  .form-select {
    @include text($color: $color-grey, $size: 14px, $weight: 400);
    text-transform: capitalize;
  }

  .btn-file {
    height: 175px;
    margin: 10px;
    border: 2px dashed $color-yellow;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding: 25px 10px;

    p {
      @include text($color: $color-grey, $size: 14px, $weight: 400);
    }

    .btn-add {
      background: #FFD07B;
      @include text($color: $color-white, $size: 16px, $weight: 700);
    }

    input[type="file"] {
      position: absolute;
      left: 0;
      height: 100%;
      width: 100%;
      cursor: pointer;
      opacity: 0;
    }
  }

  .preview-file {
    position: relative;

    img,
    video {
      border-radius: 5px;
    }

    .delete-btn {
      border: none;
      border-radius: 5px;
      background: $color-white;
      position: absolute;
      top: 5px;
      right: 17.5px;
      box-shadow: 0 0 10px #00000025;
      opacity: .65;
    }

    .delete-btn:hover {
      opacity: 1;
    }
  }

  .btn-submit {
    background: $color-blue;
    @include text($color: $color-white, $size: 20px, $weight: 700);
    display: flex !important;
    justify-content: center !important;
    width: 100%;
    margin-top: 50px !important;

    &:hover {
      background: $color-blue-light;
    }
  }
}