@import "../../style/index.scss";

.rgpd-head {
    text-align: center;
    margin-bottom: 30px;
    font-size: 16px;

    h1 {
        font-size: 28px;
    }

    h2 {
        font-size: 24px;
    }
}

.rgpd-body {
    h3 {
        font-size: 20px;
    }

    h4 {
        font-size: 18px;
    }

    img {
        margin: 30px;
    }
}