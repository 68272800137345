@import "../../style/index.scss";

.card-slider {
    position: relative !important;
    padding-bottom: 75px !important;

    @media (max-width: 1280px) {

        .swiper-button-prev,
        .swiper-button-next {
            display: none;
        }
    }

    .swiper-pagination-bullet {
        opacity: .05;
    }

    .swiper-pagination-bullet-active {
        width: 12.5px;
        background: $color-black;
        border-radius: 20px !important;
        opacity: 1;
    }

    .swiper-button-prev {
        left: 40%;
    }

    .swiper-button-next {
        right: 40%;
    }

    .swiper-button-prev,
    .swiper-button-next {
        position: absolute;
        z-index: 11;
        top: 95%;
        transform: translateY(17.5%);
    }

    .swiper-button-prev::after {
        content: "";
        background-image: url("../../assets/icon/arrow.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%;
        height: 100%;
    }

    .swiper-button-next::after {
        content: "";
        background-image: url("../../assets/icon/arrow.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%;
        height: 100%;
        transform: rotate(180deg);
    }
}